/*
 |-----------------------------------------------------------------------------
 | components/atoms/Input/Input.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import React, { useState } from 'react';

import { get } from 'lodash';
// import { ray } from 'node-ray/web';
import classNames from 'classnames';

import * as IInput from './types';

import styles from './Input.module.scss';

const Input: React.FC<IInput.IProps> = ({
	className,
	hideLabel,
	id,
	isDisabled,
	isOptional,
	label,
	name,
	placeholder,
	type,
}) => {
	const [focus, setFocus] = useState(false);

	const classes = classNames(
		get(styles, 'block'),
		{
			[get(styles, 'isDisabled')]: isDisabled,
			[get(styles, 'isFocus')]: focus,
		},
		className
	);

	// ray('Debug atom Input:', {
	// 	className: className,
	// 	hideLabel: hideLabel,
	// 	id: id,
	// 	isDisabled: isDisabled,
	// 	isOptional: isOptional,
	// 	label: label,
	// 	name: name,
	// 	placeholder: placeholder,
	// 	type: type,
	// }).red();

	return (
		<label className={classes} htmlFor={id}>
			<input
				className={get(styles, 'control')}
				defaultValue=""
				disabled={isDisabled}
				id={id}
				name={name}
				onBlur={() => setFocus(false)}
				onFocus={() => setFocus(true)}
				placeholder={placeholder || ' '}
				required={!isOptional}
				type={type}
			/>
			<span
				className={hideLabel ? 'u-screen-reader' : get(styles, 'label')}
			>
				{label}
			</span>
		</label>
	);
};

export default Input;
